<template>
  <div style="display: flex; flex-wrap: wrap; justify-content: center">
    <div style="height: 550px; width: 550px" class="me-4 my-2">
      <Vue3ChartJs
        :type="merchantChart.type"
        :data="merchantChart.data"
        ref="merchantChartRef"
      ></Vue3ChartJs>
    </div>
    <div style="height: 550px; width: 550px" class="ms-4 my-2">
      <Vue3ChartJs
        :type="sourceChart.type"
        :data="sourceChart.data"
        ref="sourceChartRef"
      ></Vue3ChartJs>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

// function getRandomColor() {
  // var letters = "0123456789ABCDEF".split("");
  // var color = "#";
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  // return color;
// }

function getChartData(result) {
  var labels = [];
  var data1 = [];
  var data2 = [];
  //var color = [];
  var color = [
    "#B60E91",
    "#C1E5AA",
    "#F2E643",
    "#8A07D4",
    "#4EE28C",
    "#D4FE3C",
    "#BA7426",
    "#7D1EDD",
    "#CBCFE8",
    "#BBC94D",
    "#2AD38C",
    "#53A987",
    "#E1CE57",
    "#FE48B0",
    "#9C78B3",
    "#8F47BA",
    "#ABD136",
    "#EA3E49",
    "#200A7C",
    "#0C1E4F",
    "#BDEB48",
    "#221B56",
    "#854A12",
    "#6E96D5",
    "#5653B2",
    "#C89FC0",
    "#C9956C",
    "#E237AA",
    "#4486DF",
    "#DAF267",
    "#4F3654"
  ];
  for (const key in result) {
    labels.push(key);
    data1.push(result[key].value);
    data2.push(result[key].profit - result[key].fee);
    //color.push(getRandomColor());
  }
  //console.log(color);
  return { labels, color, data1, data2 };
}

function getMapByProperty(map, entry, property) {
  if (!entry[property]) {
    return map;
  }
  if (!map[entry[property]]) {
    map[entry[property]] = {};
    map[entry[property]].value = 0;
    map[entry[property]].fee = 0;
    map[entry[property]].profit = 0;
  }
  map[entry[property]].value += entry.quantity * entry.price;
  map[entry[property]].fee += entry.quantity * entry.fee;
  map[entry[property]].profit += entry.soldPrice > 0 ? entry.quantity * (entry.soldPrice - entry.price) : 0;
  return map;
}

function getMerchantChart(self) {
  var result = self.chartData.reduce(function (map, entry) {
    return getMapByProperty(map, entry, "retailer");
  }, {});
  var { labels, color, data1, data2 } = getChartData(result);
  self.updateMerchantChart(labels, [
    { backgroundColor: color, data: data1 },
    { backgroundColor: color, data: data2 },
  ]);
}

function getSourceChart(self) {
  var result = self.chartData.reduce(function (map, entry) {
    return getMapByProperty(map, entry, "source");
  }, {});
  var { labels, color, data1, data2 } = getChartData(result);
  self.updateSourceChart(labels, [
    { backgroundColor: color, data: data1 },
    { backgroundColor: color, data: data2 },
  ]);
}

export default {
  name: "DealReport",
  components: {
    Vue3ChartJs,
  },
  setup() {
    const merchantChartRef = ref(null);

    const merchantChart = {
      type: "pie",
      data: {},
      options: {
        plugins: {},
      },
    };

    const updateMerchantChart = (labels, datasets) => {
      merchantChart.options.plugins.title = {
        text: "Updated Chart",
        display: true,
      };
      merchantChart.data.labels = labels;
      merchantChart.data.datasets = datasets;
      merchantChartRef.value.update(250);
    };

    const sourceChartRef = ref(null);

    const sourceChart = {
      type: "pie",
      data: {},
      options: {
        plugins: {},
      },
    };

    const updateSourceChart = (labels, datasets) => {
      sourceChart.options.plugins.title = {
        text: "Updated Chart",
        display: true,
      };
      sourceChart.data.labels = labels;
      sourceChart.data.datasets = datasets;
      sourceChartRef.value.update(250);
    };

    return {
      merchantChart,
      updateMerchantChart,
      merchantChartRef,
      sourceChart,
      updateSourceChart,
      sourceChartRef,
    };
  },
  props: {
    chartData: Array
  },
  watch: {
    chartData: function(newData, oldData) {
      console.log(oldData);
      if(newData && newData.length > 0) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      getMerchantChart(this);
      getSourceChart(this);
    }
  }
};
</script>
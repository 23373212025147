<template>
  <BreadCrumb :breadcrumbs="breadcrumbs" />
  <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
  <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
  <form class="form-inline">
    <div class="input-group mb-3 w-75 ms-1">
      <span class="input-group-text fa fa-search"/>
      <input type="text" class="form-control" placeholder="Search by Product or Source" v-model="term" @input="filterList">
      <span class="input-group-text fa fa-trash" @click="term=''; filterList();" style="cursor: pointer;"></span>
    </div>
     <router-link class="btn btn-primary float-end" style="width: 100px;" to="/deal/new">New Deal</router-link>
  </form>
  <div class="mb-3 ms-2">
      <span class="me-4">Profit: ${{(totalProfit - totalFee).toFixed(2)}}</span>
      <span class="me-4">Total Value: ${{totalValue.toFixed(2)}}</span>
      <span class="me-4">Total Fee: ${{totalFee.toFixed(2)}}</span>
      <span class="me-4">Total Quantity: {{totalQuantity}}</span>
  </div>
  <div class="mb-3 row">
    <select v-model="status" class="form-select ms-3 me-2" style="width: 120px !important" @change="getList">
      <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
    </select>
    <select v-model="merchantType" class="form-select ms-3 me-2" style="width: 150px !important" @change="getList">
      <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
    </select>
    <datepicker v-model="startDate" typeable class="form-control" style="width: 280px" placeholder="Start Date"/>
    <datepicker v-model="endDate" typeable class="form-control" style="width: 280px" placeholder="End Date"/>
    <button class="btn btn-primary" style="width: 130px;" @click="showReport=!showReport">{{showReport ? "Hide" : "Show"}} Report</button>
  </div>
  <div class="clearfix"></div>
  <DealReport :chartData="filteredTableBody" v-show="showReport"></DealReport>
  <Table :tableBody="filteredTableBody" :tableHeader="tableHeader"></Table>
</template>

<script>
import axios from "axios";
import DealReport from '../views/DealReport.vue'

function getAction(item, openModal) {
    var result = [];
    if (item.status === "Active") {
      result.push({ type: "button", click: function() { openModal(item.id, "confirm") }, name: "<span class='fa fa-power-off'></span>", class: "btn-success" })
    }
    result.push({ type: "link", link: "/deal/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" });
    result.push({ type: "link", link: "/deal/edit?action=COPY&id=" + item.id, class: "btn btn-info btn-sm fa fa-copy" });
    result.push({ type: "button", click: function() { openModal(item.id, "delete") }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" });
    return result;
}

export default {
  name: "Deal",
  mounted() {
    this.getList();
  },
  watch: {
    startDate: function () {
      this.getList();
    },
    endDate: function() {
      this.getList();
    }
  },
  components: {
    DealReport
  },
  data() {
    return {
      tableHeader: [
        "Date",
        "Retailer",
        "Product",
        "Quantity",
        "Price",
        "Fee",
        "Sold Price",
        "Profit",
        "Status",
        "Source",
        "Action"
      ],
      tableBody: [],
      filteredTableBody: [],
      breadcrumbs: [{ name: "Deal" }],
      retailerList: ["All", "Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sony", "Sephora", "eBay", "Staples", "Newegg"],
      statusList: ["All", "Active", "Inactive"],
      status: "Active",
      merchantType: "All",
      showReport: false,
      startDate: new Date("2020-01-01T00:00:00"),
      endDate: new Date(new Date().setDate(new Date().getDate()+1)),
      alertContent: "",
      alertClass: "alert-danger",
      total: 0,
      failed: 0,
      loading: false,
      term: "",
      showModal: false,
      totalValue: 0,
      totalFee: 0,
      totalProfit: 0,
      totalQuantity: 0,
      currentId: "",
      opt: ""
    };
  },
  methods: {
    openModal(id, opt) {
      this.showModal = true;
      this.currentId = id;
      this.opt = opt;
    },
    confirmModal() {
      if (this.opt === "delete") {
        axios.delete("/deal/" + this.currentId).then(() => {
            this.getList();
        }).catch(error => {
            this.alertContent = error.response ? error.response.data : error;
            this.alertClass = "alert-danger";
        })
      } else if (this.opt === "confirm") {
        axios.put("/deal/" + this.currentId).then(() => {
            this.getList();
        }).catch(error => {
            this.alertContent = error.response ? error.response.data : error;
            this.alertClass = "alert-danger";
        })
      }
      this.showModal = false;
    },
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios
        .get("/deal?status=" + this.status + "&merchantType=" + this.merchantType +"&startDate=" + this.startDate.toISOString().substring(0,10) + "&endDate=" + this.endDate.toISOString().substring(0,10))
        .then((response) => {
          var openModal = this.openModal;
          this.tableBody = response.data.map((item) => {
            return {
              date: item.date,
              retailer: item.merchantType,
              product: item.product,
              quantity: item.quantity,
              price: item.price,
              fee: item.fee,
              soldPrice: item.soldPrice,
              profit: item.soldPrice ? (item.quantity * (item.soldPrice - item.price - item.fee)).toFixed(2) : 0,
              status: { type: "status", class: "badge bg-" + (item.status === "Active" ? "success" : "secondary"), name: item.status },
              source: item.source,
              action: getAction(item, openModal)
            };
          });

          this.filterList();
          this.loading = false;
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.loading = false;
        });
    },
    filterList() {
      if (this.term) {
        this.filteredTableBody = this.tableBody.filter(item => 
            (item.source && item.source.toLowerCase().indexOf(this.term.toLowerCase()) > -1) || 
            item.product.toLowerCase().indexOf(this.term.toLowerCase()) > -1
      )} else {
        this.filteredTableBody = this.tableBody;
      }
      if (this.filteredTableBody && this.filteredTableBody.length > 0) {
        this.totalValue = this.filteredTableBody.reduce((curr, item) => { return curr += item.quantity * item.price }, 0);
        this.totalFee = this.filteredTableBody.reduce((curr, item) => { return curr += item.quantity * item.fee }, 0);
        this.totalProfit = this.filteredTableBody.reduce((curr, item) => { 
          if (item.soldPrice > 0) {
            return curr += (item.quantity * (item.soldPrice - item.price));
          } else { 
            return curr;
          }
        }, 0);
        this.totalQuantity = this.filteredTableBody.reduce((curr, item) => { return curr += item.quantity }, 0);
      } else {
        this.totalValue = 0;
        this.totalFee = 0;
        this.totalProfit = 0;
        this.totalQuantity = 0;
      }
    }
  }
};
</script>

<style scoped>
.v3dp__datepicker {
  display: inline;
  width: 310px;
}
</style>